import { put, takeLatest, takeEvery, call } from "redux-saga/effects";
import * as api from "api";
import { getUser } from "actions";

const CONVERT_KOEF = 1073741824;
const convertToGbs = bytes => (bytes / CONVERT_KOEF).toFixed(2);
const convertToBytes = gbs => Number.parseInt(gbs * CONVERT_KOEF);

const convertUserFiledsToGbs = user => ({
  ...user,
  BytesToUpload: convertToGbs(user.BytesToUpload),
  BytesUploaded: convertToGbs(user.BytesUploaded || 0),
  BytesDownloaded: convertToGbs(user.BytesDownloaded || 0),
  BytesToDownload: convertToGbs(user.BytesToDownload)
});

const convertUserFiledsToBytes = user => ({
  ...user,
  BytesToUpload: convertToBytes(user.BytesToUpload),
  BytesUploaded: convertToBytes(user.BytesUploaded || 0),
  BytesDownloaded: convertToBytes(user.BytesDownloaded || 0),
  BytesToDownload: convertToBytes(user.BytesToDownload)
});

function* fetchUsers() {
  try {
    yield put({ type: "START_LOADING_DATA", payload: "users" });
    const response = yield call(api.fetchUsers);
    const users = response.data.users.map(user => convertUserFiledsToGbs(user));
    yield put({
      type: "ITEMS_RECEIVED",
      payload: { field: "users", data: users }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "users", error: error.message }
    });
  }
}

function* getUserById(action) {
  try {
    yield put({ type: "START_LOADING_DATA", payload: "user" });
    const { data } = yield call(api.getUser, action.payload.userName);
    const user = convertUserFiledsToGbs(data.user);
    yield put({
      type: "ITEM_RECEIVED",
      payload: { field: "user", data: user }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "user", error: error.message }
    });
  }
}

function* deactivateUser(action) {
  try {
    yield call(api.deactivateUser, action.payload.userName);
    yield call(fetchUsers);
  } catch (error) {
    yield put({
      type: "SET_ACTION_ERROR",
      payload: { error: error.message }
    });
  }
}

function* restoreUser(action) {
  try {
    yield call(api.restoreUser, action.payload.userName);
    yield call(fetchUsers);
  } catch (error) {
    yield put({
      type: "SET_ACTION_ERROR",
      payload: { error: error.message }
    });
  }
}

function* updateUser(action) {
  try {
    const user = convertUserFiledsToBytes(action.payload.user);
    yield call(api.updateUser, user);
    yield put(getUser(action.payload.user.UserName));
  } catch (error) {
    yield put({
      type: "SET_ACTION_ERROR",
      payload: { error: error.message }
    });
  }
}

function* updateUserPricingPlan(action) {
  try {
    const user = action.payload.user;
    yield call(api.updateUserPlan, user);
    yield put(getUser(action.payload.user.UserName));
  } catch (error) {
    yield put({
      type: "SET_ACTION_ERROR",
      payload: { error: error.message }
    });
  }
}

function* removeUserPricingPlan(action) {
  try {
    const user = action.payload.user;
    yield call(api.removeUserPlan, user);
    yield put(getUser(user));
  } catch (error) {
    yield put({
      type: "SET_ACTION_ERROR",
      payload: { error: error.message }
    });
  }
}

function* addUser(action) {
  try {
    yield put({ type: "START_LOADING_DATA", payload: "newuser" });
    const user = convertUserFiledsToBytes(action.payload.user);
    yield call(api.createUser, user);
    yield put({
      type: "ITEM_CREATED",
      payload: { field: "newuser", data: user }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "newuser", error: error.message }
    });
  }
}

function* resetPassword(action) {
  try {
    yield call(api.resetPswd, action.payload.user);
    yield put(getUser(action.payload.user.UserName));
  } catch (error) {
    yield put({
      type: "SET_ACTION_ERROR",
      payload: { error: error.message }
    });
  }
}

export default function* usersSaga() {
  yield takeLatest("LOAD_USERS", fetchUsers);
  yield takeEvery("DEACTIVATE_USER", deactivateUser);
  yield takeEvery("RESTORE_USER", restoreUser);
  yield takeEvery("GET_USER", getUserById);
  yield takeEvery("ADD_USER", addUser);
  yield takeEvery("UPDATE_USER", updateUser);
  yield takeEvery("RESET_PASSWORD", resetPassword);
  yield takeEvery("UPDATE_USER_PLAN", updateUserPricingPlan);
  yield takeEvery("REMOVE_USER_PLAN", removeUserPricingPlan);
}
