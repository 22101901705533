import React from "react";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import { connect } from "react-redux";

import { deletePlan } from "actions";

const link = plan => `/main/edit-plan?plan=${plan}`;

const PlansActions = props => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        justIcon
        round
        simple
        to={link(props.plan.PlanId)}
        component={Link}
        color="success"
        className="edit"
      >
        <Edit />
      </Button>
      <Button
        justIcon
        round
        simple
        onClick={() => props.deletePlan(props.plan.PlanId)}
        color="danger"
        className="remove"
      >
        <Close />
      </Button>
    </div>
  );
};

const mapDispatchToProps = {
  deletePlan
};

export default connect(
  null,
  mapDispatchToProps
)(PlansActions);
