import { put, takeLatest, call } from "redux-saga/effects";
import { logOut, getCognitoUser } from "api/auth";

const convertToUser = data => ({
  firstName: data.given_name,
  lastName: data.family_name,
  email: data.email
});

function* signOut() {
  try {
    yield call(logOut);
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "logout", error: error.message }
    });
  }
}

function* setAdminData() {
  try {
    const response = yield call(getCognitoUser);
    const admin =
      (response && response.attributes && convertToUser(response.attributes)) ||
      {};
    yield put({
      type: "SET_ADMIN",
      payload: { admin }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "login", error: error.message }
    });
  }
}

export default function* authSaga() {
  yield takeLatest("LOGOUT", signOut);
  yield takeLatest("GET_ADMIN", setAdminData);
}
