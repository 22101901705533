import React from "react";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Restore from "@material-ui/icons/Restore";
// core components
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import { connect } from "react-redux";

import { deactivateUser, restoreUser } from "actions";

const link = user => `/main/edit-user?user=${user}`;

const UserActions = props => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {!props.user.Deactivated ? (
        <>
          <Button
            justIcon
            round
            simple
            to={link(props.user.UserName)}
            component={Link}
            color="success"
            className="edit"
          >
            <Edit />
          </Button>
          <Button
            justIcon
            round
            simple
            onClick={() => props.deactivateUser(props.user.UserName)}
            color="danger"
            className="remove"
          >
            <Close />
          </Button>
        </>
      ) : (
        <Button
          justIcon
          round
          simple
          onClick={() => props.restoreUser(props.user.UserName)}
          color="info"
          className="restore"
        >
          <Restore />
        </Button>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  deactivateUser,
  restoreUser
};

export default connect(
  null,
  mapDispatchToProps
)(UserActions);
