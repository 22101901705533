import { fork } from "redux-saga/effects";
import usersSaga from "./users";
import plansSaga from "./plans";
import logsSaga from "./logs";
import authSaga from "./auth";

export default function* rootSaga() {
  yield fork(usersSaga);
  yield fork(plansSaga);
  yield fork(logsSaga);
  yield fork(authSaga);
}
