import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Radio from "@material-ui/core/Radio";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Clearfix from "components/Clearfix/Clearfix.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { Formik } from "formik";

const useStyles = makeStyles(styles);

const initialObj = {
  BillingPeriod: "",
  Code: "",
  GBytesToUpload: "",
  GBytesToDownload: "",
  Price: "",
  Type: "Basic",
  Overwrite: false
};

const PricingPlanForm = ({
  buttonText = "",
  fields = [],
  initialValues,
  validationSchema,
  onSubmit
}) => {
  const classes = useStyles();
  const isVissible = field => fields.indexOf(field) !== -1;
  const initValues = initialValues || initialObj;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValues}
      onSubmit={(values, actions) => {
        onSubmit(values);
        actions.resetForm(initialObj);
      }}
      validationSchema={validationSchema}
    >
      {({ touched, errors, values, handleChange, handleSubmit }) => {
        return (
          <form>
            {isVissible("Code") ? (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Pricing Plan Code
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      value: values.Code,
                      onChange: handleChange("Code")
                    }}
                    error={Boolean(touched.Code && errors.Code)}
                    helperText={touched.Code && errors.Code}
                  />
                </GridItem>
              </GridContainer>
            ) : null}
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  Billing Period
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="BillingPeriod"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    autoComplete: "off",
                    value: values.BillingPeriod,
                    onChange: handleChange("BillingPeriod")
                  }}
                  error={Boolean(touched.BillingPeriod && errors.BillingPeriod)}
                  helperText={touched.BillingPeriod && errors.BillingPeriod}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  GBs To Upload
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="bytestoupload"
                  inputProps={{
                    autoComplete: "off",
                    value: values.GBytesToUpload,
                    onChange: handleChange("GBytesToUpload")
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={values.GBytesToUpload}
                  error={Boolean(
                    touched.GBytesToUpload && errors.GBytesToUpload
                  )}
                  helperText={touched.GBytesToUpload && errors.GBytesToUpload}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>
                  GBs To Download
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="bytestodownload"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    autoComplete: "off",
                    value: values.GBytesToDownload,
                    onChange: handleChange("GBytesToDownload")
                  }}
                  value={values.GBytesToDownload}
                  error={Boolean(
                    touched.GBytesToDownload && errors.GBytesToDownload
                  )}
                  helperText={
                    touched.GBytesToDownload && errors.GBytesToDownload
                  }
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelHorizontal}>Price</FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  id="bytestodownload"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    autoComplete: "off",
                    value: values.Price,
                    onChange: handleChange("Price")
                  }}
                  value={values.Price}
                  error={Boolean(touched.Price && errors.Price)}
                  helperText={touched.Price && errors.Price}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    classes.labelHorizontal +
                    " " +
                    classes.labelHorizontalRadioCheckbox
                  }
                >
                  Type
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <div className={classes.inlineChecks}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={values.Type === "Basic"}
                        onChange={handleChange("Type")}
                        value="Basic"
                        name="radio button enabled"
                        aria-label="Basic"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Basic"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={values.Type === "Standard"}
                        onChange={handleChange("Type")}
                        value="Standard"
                        name="radio button enabled"
                        aria-label="Standard"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Standard"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={values.Type === "Pro"}
                        onChange={handleChange("Type")}
                        value="Pro"
                        name="radio button enabled"
                        aria-label="Pro"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Pro"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem xs={12} sm={12} md={10}>
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={values.Overwrite}
                        onClick={handleChange("Overwrite")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Overwritable"
                  />
                </div>
              </GridItem>
            </GridContainer>
            <Button
              color="info"
              //disabled={formikProps.isSubmitting}
              onClick={handleSubmit}
              className={classes.updateProfileButton}
            >
              {buttonText}
            </Button>
            <Clearfix />
          </form>
        );
      }}
    </Formik>
  );
};

export default PricingPlanForm;
