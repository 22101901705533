import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// core components
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);

const SelectPricingPlan = props => {
  const classes = useStyles();
  const { plans, handler, initValue } = props;
  const [selectedPlan, setSelectedPlan] = useState(initValue || "");

  useEffect(() => {
    setSelectedPlan(initValue);
  }, [initValue]);

  const handleSimple = event => {
    setSelectedPlan(event.target.value);
    handler && handler(event.target.value);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelHorizontal}>
          Choose Pricing Plan
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={10}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Choose Pricing Plan
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={selectedPlan}
            onChange={handleSimple}
            inputProps={{
              name: "planSelect",
              id: "simple-select"
            }}
          >
            {(plans || []).map(plan => (
              <MenuItem
                key={plan.PlanId}
                disabled={plan.deleted}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={plan.PlanId}
              >
                {plan.Code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
};

export default SelectPricingPlan;
