import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/People";

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";

import UserActions from "./UserActions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const UsersTable = ({ data, loading }) => {
  const items = data.map(item => ({
    ...item,
    actions: <UserActions user={item} />
  }));
  const classes = useStyles();
    console.log(items);
  return (
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Users</h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={items}
            columns={[
              {
                Header: "User Name",
                accessor: "UserName"
              },
              {
                Header: "Start Date",
                accessor: "StartDate",
                  Cell: row => <span>{new Date(+row.value).toLocaleString()}</span>
              },
              {
                Header: "Folder",
                accessor: "Folder"
              },
              {
                Header: "Gbs To Upload",
                accessor: "BytesToUpload"
              },
              {
                Header: "Gbs Uploaded",
                accessor: "BytesUploaded"
              },
              {
                Header: "Gbs To Download",
                accessor: "BytesToDownload"
              },
              {
                Header: "Gbs Downloaded",
                accessor: "BytesDownloaded"
              },
              {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
              }
            ]}
            filterable
            defaultPageSize={10}
            showPaginationTop
            showPaginationBottom={false}
            className="-striped -highlight"
            loading={loading}
            LoadingComponent={LoadingComponent}
          />
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default UsersTable;
