import React, { useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import NewPlan from "@material-ui/icons/Assignment";
import * as yup from "yup";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";
import PricingForm from "components/PricingPlanForm/PricingPlanForm";
import PricingPlanCard from "components/PricingPlanCard/PricingPlanCard";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { updatePlan, getPlan } from "actions";

const useStyles = makeStyles(styles);

const EditPlan = props => {
  const planId = new URLSearchParams(window.location.search).get("plan");
  const { getPlan, data } = props;

  useEffect(() => {
    planId && getPlan(planId);
  }, [planId, getPlan]);
  const classes = useStyles();
  const formProps = {
    buttonText: "Update Pracing Plan",
    fields: [
      "BillingPeriod",
      "Code",
      "GBytesToUpload",
      "GBytesToDownload",
      "Overwrite",
      "Price",
      "Type"
    ],
    validationSchema: yup.object().shape({
      BillingPeriod: yup
        .number()
        .required()
        .label("BillingPeriod"),
      Code: yup
        .string()
        .required()
        .label("Code"),
      GBytesToDownload: yup
        .number()
        .required()
        .min(25)
        .label("GBytes To Download"),
      GBytesToUpload: yup
        .number()
        .required()
        .min(25)
        .label("GBytes To Upload"),
      Price: yup
        .number()
        .required()
        .label("Price")
    })
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <NewPlan />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Update Pricing Plan</h4>
          </CardHeader>
          <CardBody>
            <LoadingComponent loading={props.loading} />
            <PricingForm
              {...formProps}
              initialValues={data}
              onSubmit={props.updatePlan}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <PricingPlanCard plan={data} loading={props.loading} />
      </GridItem>
    </GridContainer>
  );
};

const mapDispatchToProps = {
  getPlan,
  updatePlan
};

const mapStateToProps = state => ({
  ...state.plan
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPlan);
