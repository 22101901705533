const rootReducer = (state = {}, action) => {
  switch (action.type) {
    case "START_LOADING_DATA":
      return {
        ...state,
        [action.payload]: {
          loading: true,
          data: state[action.payload] ? state[action.payload].data : null
        },
        error: null
      };
    case "ITEMS_RECEIVED":
      return {
        ...state,
        [action.payload.field]: { data: action.payload.data, loading: false }
      };
    case "ITEM_RECEIVED":
      return {
        ...state,
        [action.payload.field]: { data: action.payload.data, loading: false }
      };
    case "ITEM_CREATED":
      return {
        ...state,
        [action.payload.field]: { data: action.payload.data, loading: false }
      };
    case "SET_ADMIN":
      return {
        ...state,
        admin: action.payload.admin
      };
    case "SET_DATA_ERROR":
      return {
        ...state,
        error: action.payload.error,
        [action.payload.field]: {
          loading: false,
          data: state[action.payload.field]
            ? state[action.payload.field].data
            : null
        }
      };
    case "SET_ACTION_ERROR":
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default rootReducer;
