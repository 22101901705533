import React from "react";

const ProSVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon all</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icon-all"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="chart-donut">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M17.4976192,16.365 L20.1216762,17.88 C17.1636635,22.0034283 11.490735,23.2892286 7.00472339,20.6992286 C5.74898656,19.9742286 4.7138715,19.0071005 3.92,17.9021263 L3.92,17.9021263 L6.55501887,16.3981397 C7.07917894,17.0702679 7.72530053,17.6511524 8.50472339,18.1011524 C11.5617931,19.8661524 15.3782667,19.0558261 17.4976192,16.365 L17.4976192,16.365 Z M11,2 L11,5.03 C7.61,5.52 5,8.42 5,11.95 C5,12.85 5.18,13.7 5.5,14.49 L2.88,16.02 C2.32,14.78 2,13.4 2,11.95 C2,6.77 5.95,2.5 11,2 Z M13,2 L13,5.03 C16.39,5.52 19,8.42 19,11.95 C19,12.85 18.82,13.7 18.5,14.49 L21.12,16.02 C21.68,14.78 22,13.4 22,11.95 C22,6.77 18.05,2.5 13,2"
          id="Shape"
          fill="#fff"
          fill-rule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
);

export default ProSVG;
