/* eslint react-hooks/exhaustive-deps: "off" */
import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import SwitchBlock from "components/SwitchBlock/SwitchBlock";
import SearchInput from "components/SearchInput/SearchInput";
import UsersTable from "./UsersTable";
import UsersCards from "./UsersCards";

import { loadUsers } from "actions";

const Users = props => {
  const [selected, setSelected] = useState("table");
  const [searchFilter, setSearch] = useState("");

  const filteredData =
    selected === "cards" && searchFilter && props.data
      ? props.data.filter(user => user.UserName.indexOf(searchFilter) !== -1)
      : props.data || [];

  useEffect(() => {
    props.loadUsers();
  }, []);

  return selected === "cards" ? (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <SearchInput onSearch={setSearch} searchString={searchFilter} />
          <SwitchBlock selected={selected} setSelected={setSelected} />
        </div>
      </GridItem>
      <UsersCards data={filteredData} loading={props.loading} />
    </GridContainer>
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <SwitchBlock selected={selected} setSelected={setSelected} />
        </div>
      </GridItem>
      <UsersTable data={props.data || []} loading={props.loading} />
    </GridContainer>
  );
};

const mapDispatchToProps = {
  loadUsers
};

const mapStateToProps = state => ({
  ...state.users
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
