export const loadUsers = () => ({
  type: "LOAD_USERS"
});

export const getUser = userName => ({
  type: "GET_USER",
  payload: { userName }
});

export const deactivateUser = userName => ({
  type: "DEACTIVATE_USER",
  payload: { userName }
});

export const restoreUser = userName => ({
  type: "RESTORE_USER",
  payload: { userName }
});

export const addUser = user => ({
  type: "ADD_USER",
  payload: { user }
});

export const updateUser = user => ({
  type: "UPDATE_USER",
  payload: { user }
});

export const resetPassword = user => ({
  type: "RESET_PASSWORD",
  payload: { user }
});

export const updateUserPlan = user => ({
  type: "UPDATE_USER_PLAN",
  payload: { user }
});

export const removeUserPlan = user => ({
  type: "REMOVE_USER_PLAN",
  payload: { user }
});

export const loadPlans = () => ({
  type: "LOAD_PLANS"
});

export const loadArchive = () => ({
  type: "LOAD_ARCHIVE"
});

export const deletePlan = plan => ({
  type: "DELETE_PLAN",
  payload: { plan }
});

export const addPlan = plan => ({
  type: "ADD_PLAN",
  payload: { plan }
});

export const updatePlan = plan => ({
  type: "UPDATE_PLAN",
  payload: { plan }
});

export const getPlan = plan => ({
  type: "GET_PLAN",
  payload: { plan }
});

export const loadLogs = () => ({
  type: "LOAD_LOGS"
});

export const logOut = () => ({
  type: "LOGOUT"
});

export const getAdmin = () => ({
  type: "GET_ADMIN"
});
