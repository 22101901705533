import axios from "axios";
import { getCurrentUser } from "./auth";
const host = process.env.REACT_APP_URL;

axios.interceptors.request.use(
  async config => {
    const user = await getCurrentUser();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: user.token,
        "Content-Type": "application/json"
      }
    };
  },
  err => Promise.reject(err)
);

const url = (item, action) => `${host}${item}/${action}`;

export const fetchLogs = () => axios.get(url("logs", "list"));

export const fetchUsers = () => axios.get(url("users", "list"));
export const createUser = user => axios.post(url("users", "create"), user);
export const updateUser = user => axios.put(url("users", "update"), user);
export const resetPswd = user => axios.put(url("users", "pswd"), user);
export const updateUserPlan = user => axios.put(url("users", "plan"), user);
export const removeUserPlan = userName =>
  axios.get(url("users", "plan"), {
    params: {
      userName
    }
  });

export const getUser = userName =>
  axios.get(url("users", "get"), {
    params: {
      userName
    }
  });

export const deactivateUser = userName =>
  axios.get(url("users", "delete"), {
    params: {
      userName
    }
  });

export const restoreUser = userName =>
  axios.get(url("users", "restore"), {
    params: {
      userName
    }
  });

export const fetchPlans = () => axios.get(url("plans", "list"));
export const updatePlan = plan => axios.put(url("plans", "update"), plan);
export const createPlan = item => axios.post(url("plans", "create"), item);

export const fetchDeletedPlans = () =>
  axios.get(url("plans", "list"), {
    params: {
      deactivated: true
    }
  });

export const deactivatePlan = id =>
  axios.get(url("plans", "delete"), {
    params: {
      id
    }
  });

export const getPlan = planId =>
  axios.get(url("plans", "get"), {
    params: {
      planId
    }
  });
