import React from "react";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import Table from "@material-ui/icons/List";
import Cards from "@material-ui/icons/ViewModule";
import buttonGroupStyles from "assets/jss/material-dashboard-pro-react/buttonGroupStyle";

const styles = {
  ...buttonGroupStyles,
  switchButton: {
    marginLeft: "-1px",
    border: "1px solid #00acc1",
    boxShadow:
      "0 2px 2px 0 rgba(0, 172, 193, 0.14), 0 3px 1px -2px rgba(0, 172, 193, 0.2), 0 1px 5px 0 rgba(0, 172, 193, 0.12)"
  }
};

const useStyles = makeStyles(styles);

const SwitchBlock = ({ selected, setSelected }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonGroup}>
      <Button
        color="info"
        simple={selected !== "table"}
        justIcon
        className={cx(classes.firstButton, classes.switchButton)}
        onClick={() => setSelected("table")}
      >
        <Table />
      </Button>
      <Button
        justIcon
        simple={selected !== "cards"}
        color="info"
        className={cx(classes.lastButton, classes.switchButton)}
        onClick={() => setSelected("cards")}
      >
        <Cards />
      </Button>
    </div>
  );
};

export default SwitchBlock;
