import React from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import PricingPlanCard from "components/PricingPlanCard/PricingPlanCard";
import PlansActions from "./PlansActions";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";

const actions = props => <PlansActions {...props} />;

const PlansCards = ({ data, loading }) => (
  <>
    <LoadingComponent loading={loading} />
    {data.map(plan => (
      <GridItem xs={12} sm={6} md={4} lg={3} key={plan.Code}>
        <PricingPlanCard plan={plan} actions={actions} />
      </GridItem>
    ))}
  </>
);

export default PlansCards;
