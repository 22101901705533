import React from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import UserCard from "components/UserCard/UserCard.js";
import UserActions from "./UserActions";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";

const actions = props => <UserActions {...props} />;

const UsersCards = ({ data, loading }) => (
  <>
    <LoadingComponent loading={loading} />
    {data.map(user => (
      <GridItem xs={12} sm={6} md={4} lg={3} key={user.UserName}>
        <UserCard user={user} actions={actions} />
      </GridItem>
    ))}
  </>
);

export default UsersCards;
