import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "react-spinkit";

const styles = {
  processingOverlay: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255,255,255,0.5)",
    zIndex: 20000
  },
  spinner: {
    width: 30,
    height: 30,
    color: "steelblue",
    margin: "0 12px",
    zIndex: 30000
  }
};

const useStyles = makeStyles(styles);

const ActionsSpinner = ({ loading }) => {
  const classes = useStyles();
  return loading ? (
    <div className={classes.processingOverlay}>
      <Spinner
        className={classes.spinner}
        name="line-spin-fade-loader"
        fadeIn="none"
        data-testid="action-spinner"
      />
    </div>
  ) : null;
};

export default ActionsSpinner;
