import React from "react";

const BasicSVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon basic</title>
    <desc>Created with Sketch.</desc>
    <g
      id="icon-basic"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="chart-donut">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M2,2 L2,5.03 C5.39,5.52 8,8.42 8,11.95 C8,12.85 7.82,13.7 7.5,14.49 L10.12,16.02 C10.68,14.78 11,13.4 11,11.95 C11,6.77 7.05,2.5 2,2"
          id="Path"
          fill="#fff"
          fill-rule="nonzero"
          transform="translate(6.500000, 9.010000) scale(-1, 1) translate(-6.500000, -9.010000) "
        ></path>
        <path
          d="M7.30083808,11.2621143 L7.30083808,14.2921143 C10.6908381,14.7821143 13.3008381,17.6821143 13.3008381,21.2121143 C13.3008381,22.1121143 13.1208381,22.9621143 12.8008381,23.7521143 L15.4208381,25.2821143 C15.9808381,24.0421143 16.3008381,22.6621143 16.3008381,21.2121143 C16.3008381,16.0321143 12.3508381,11.7621143 7.30083808,11.2621143"
          id="Path"
          fill-opacity="0.5"
          fill="#fff"
          fill-rule="nonzero"
          transform="translate(11.800838, 18.272114) rotate(120.000000) translate(-11.800838, -18.272114) "
        ></path>
        <path
          d="M13,2 L13,5.03 C16.39,5.52 19,8.42 19,11.95 C19,12.85 18.82,13.7 18.5,14.49 L21.12,16.02 C21.68,14.78 22,13.4 22,11.95 C22,6.77 18.05,2.5 13,2"
          id="Path"
          fill-opacity="0.5"
          fill="#fff"
          fill-rule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
);

export default BasicSVG;
