import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import * as generator from "generate-password";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import SelectPricingPlan from "./SelectPricingPlan";
import Clearfix from "components/Clearfix/Clearfix.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { Formik } from "formik";

const useStyles = makeStyles(styles);

const initialObj = {
  UserName: "",
  Password: "",
  BytesToUpload: "",
  BytesToDownload: "",
  PricingPlanId: "",
};

const UserForm = ({
  buttonText = "",
  fields = [],
  dictionaries = {},
  initialValues,
  validationSchema,
  onSubmit,
}) => {
  const classes = useStyles();
  const isVissible = (field) => fields.indexOf(field) !== -1;
  const initValues = initialValues
    ? { ...initialValues, Password: "" }
    : initialObj;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValues}
      onSubmit={(values, actions) => {
        onSubmit(values);
        actions.resetForm(initialObj);
      }}
      validationSchema={validationSchema}
    >
      {({ touched, errors, values, handleChange, handleSubmit }) => {
        return (
          <form>
            {isVissible("UserName") ? (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    User Name
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "off",
                      value: values.UserName,
                      onChange: handleChange("UserName"),
                    }}
                    error={Boolean(touched.UserName && errors.UserName)}
                    helperText={touched.UserName && errors.UserName}
                  />
                </GridItem>
              </GridContainer>
            ) : null}
            {isVissible("Password") ? (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    Password
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <CustomInput
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      autoComplete: "off",
                      value: values.Password,
                      onChange: handleChange("Password"),
                    }}
                    error={Boolean(touched.Password && errors.Password)}
                    helperText={touched.Password && errors.Password}
                  />
                </GridItem>
                <GridItem xs={12} sm={2}>
                  <Button
                    color="info"
                    onClick={() => {
                      const password = generator.generate({
                        length: 10,
                        numbers: true,
                      });
                      handleChange("Password")(password);
                    }}
                    className={classes.updateProfileButton}
                  >
                    Generate
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}
            {isVissible("BytesToUpload") ? (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    GBs To Upload
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="bytestoupload"
                    inputProps={{
                      autoComplete: "off",
                      value: values.BytesToUpload,
                      onChange: handleChange("BytesToUpload"),
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={values.BytesToUpload}
                    error={Boolean(
                      touched.BytesToUpload && errors.BytesToUpload
                    )}
                    helperText={touched.BytesToUpload && errors.BytesToUpload}
                  />
                </GridItem>
              </GridContainer>
            ) : null}
            {isVissible("BytesToDownload") ? (
              <GridContainer>
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelHorizontal}>
                    GBs To Download
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  <CustomInput
                    id="bytestodownload"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: values.BytesToDownload,
                      onChange: handleChange("BytesToDownload"),
                    }}
                    value={values.BytesToDownload}
                    error={Boolean(
                      touched.BytesToDownload && errors.BytesToDownload
                    )}
                    helperText={
                      touched.BytesToDownload && errors.BytesToDownload
                    }
                  />
                </GridItem>
              </GridContainer>
            ) : null}
            {isVissible("PricingPlan") ? (
              <SelectPricingPlan
                plans={dictionaries.PricingPlan}
                handler={handleChange("PricingPlanId")}
                initValue={values.PricingPlanId}
              />
            ) : null}
            <Button
              color="info"
              onClick={handleSubmit}
              className={classes.updateProfileButton}
            >
              {buttonText}
            </Button>
            <Clearfix />
          </form>
        );
      }}
    </Formik>
  );
};

export default UserForm;
