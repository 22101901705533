/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import * as yup from "yup";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";
import UserFrom from "components/UserForm/UserForm.js";
import { SuccessDialog } from "./SuccessDialog";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { addUser, loadPlans } from "actions";

const useStyles = makeStyles(styles);

const AddUser = (props) => {
  const { addUser, loading, loadPlans, plans, data } = props;
  const [open, setOpen] = useState(false);
  const [modal, showModal] = useState(false);

  useEffect(() => {
    !(plans && plans.data) && loadPlans();
  }, []);

  useEffect(() => {
    if (data && modal) {
      setOpen(true);
      showModal(false);
    }
  }, [data]);

  const classes = useStyles();
  const formProps = {
    buttonText: "Create User",
    fields: [
      "UserName",
      "Password",
      "BytesToUpload",
      "BytesToDownload",
      "PricingPlan",
    ],
    dictionaries: {
      PricingPlan: plans && plans.data,
    },
    validationSchema: yup.object().shape({
      UserName: yup
        .string()
        .required()
        .label("UserName"),
      Password: yup
        .string()
        .required()
        .label("Password"),
      BytesToDownload: yup
        .number()
        .when("PricingPlanId", {
          is: (val) => !val,
          then: yup.number().required(),
        })
        .min(25)
        .label("Bytes To Download"),
      BytesToUpload: yup
        .number()
        .when("PricingPlanId", {
          is: (val) => !val,
          then: yup.number().required(),
        })
        .min(25)
        .label("Bytes To Upload"),
    }),
  };

  const userWithPlan = (user) => ({
    ...user,
    PricingPlan: plans.data.find((plan) => plan.PlanId === user.PricingPlanId),
  });

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Add User</h4>
            </CardHeader>
            <CardBody>
              <LoadingComponent loading={loading} />
              <UserFrom
                {...formProps}
                onSubmit={(user) => {
                  addUser(user.PricingPlanId ? userWithPlan(user) : user);
                  showModal(true);
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <SuccessDialog open={open} onClose={() => setOpen(false)} user={data} />
    </>
  );
};

const mapDispatchToProps = {
  addUser,
  loadPlans,
};

const mapStateToProps = (state) => ({
  ...state.newuser,
  plans: state.plans,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser);
