import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Search from "@material-ui/icons/Search";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
const useStyles = makeStyles(styles);

const SelectPricingPlan = props => {
  const classes = useStyles();
  const searchButton = classes.top + " " + classes.searchButton;
  const { searchString, onSearch } = props;

  return (
    <div>
      <CustomInput
        formControlProps={{
          className: classes.top + " " + classes.search
        }}
        inputProps={{
          placeholder: "Search",
          value: searchString,
          onChange: event => {
            onSearch(event.target.value);
          },
          inputProps: {
            "aria-label": "Search",
            className: classes.searchInput
          }
        }}
      />
      <Button
        color="white"
        aria-label="edit"
        justIcon
        round
        disabled
        className={searchButton}
      >
        <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
      </Button>
    </div>
  );
};

export default SelectPricingPlan;
