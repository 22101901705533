import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);

export const SuccessDialog = (props) => {
  const { onClose, open, user = {} } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">User has been created</DialogTitle>
      <div style={{ padding: 24 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 24,
          }}
        >
          <p>Dear customer!</p>
          <p>New user has been succesfully created for you!</p>
          <p> <strong>URL:</strong> cloud://api.plugin.vmscloudstorage.com</p>
            <p> <strong>Login:</strong> {user && user.UserName}</p>
            <p> <strong>Password:</strong> {user && user.Password}</p>
        </div>
        <Button
          color="info"
          className={classes.updateProfileButton}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};

SuccessDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object,
};
