// components
import AddUser from "views/Users/AddUser/AddUser";
import EditUser from "views/Users/EditUser/EditUser";
import Users from "views/Users/Users/Users";
import ActionLogs from "views/ActionsLogs/ActionsLogs";
import PricingPlans from "views/PricingPlans/PlansList/PricingPlans";
import PricingArchive from "views/PricingPlans/RemovedPlansList/RemovedPricingPlans";
import AddPlan from "views/PricingPlans/AddPlan/AddPlan";
import EditPlan from "views/PricingPlans/EditPlan/EditPlan";

// @material-ui/icons
import UsersIcon from "@material-ui/icons/People";
import ListUserIcon from "@material-ui/icons/List";
import AddUserIcon from "@material-ui/icons/PlaylistAdd";
import PlansIcon from "@material-ui/icons/Widgets";
import ListPlansIcon from "@material-ui/icons/LibraryBooks";
import RemovedPlansIcon from "@material-ui/icons/HistoryOutlined";
import AddPlanIcon from "@material-ui/icons/LibraryAdd";
import LosgList from "@material-ui/icons/StorageOutlined";

const routes = [
  {
    collapse: true,
    name: "Users",
    icon: UsersIcon,
    state: "usersCollapse",
    views: [
      {
        path: "/users",
        name: "All Users",
        icon: ListUserIcon,
        component: Users,
        layout: "/main"
      },
      {
        path: "/add-user",
        name: "New User",
        icon: AddUserIcon,
        component: AddUser,
        layout: "/main"
      },
      {
        path: "/edit-user",
        name: "Edit User",
        component: EditUser,
        hidden: true,
        layout: "/main"
      }
    ]
  },
  {
    collapse: true,
    name: "Pricing Plans",
    icon: PlansIcon,
    state: "plansCollapse",
    views: [
      {
        path: "/plans",
        name: "Active Pricing Plans",
        icon: ListPlansIcon,
        component: PricingPlans,
        layout: "/main"
      },
      {
        path: "/add-plan",
        name: "New Plan",
        icon: AddPlanIcon,
        component: AddPlan,
        layout: "/main"
      },
      {
        path: "/edit-plan",
        name: "Edit Plan",
        component: EditPlan,
        hidden: true,
        layout: "/main"
      },
      {
        path: "/archive-plans",
        name: "Deactivated Pricing Plans",
        icon: RemovedPlansIcon,
        component: PricingArchive,
        layout: "/main"
      }
    ]
  },
  {
    path: "/events-logs",
    name: "Event Logs",
    icon: LosgList,
    component: ActionLogs,
    layout: "/main"
  }
];

export default routes;
