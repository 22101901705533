/* eslint react-hooks/exhaustive-deps: "off" */
import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";
import PricingPlanCard from "components/PricingPlanCard/PricingPlanCard";
import SearchInput from "components/SearchInput/SearchInput";

import { loadArchive } from "actions";

const Plans = props => {
  const [searchFilter, setSearch] = useState("");
  const { loading, data, loadArchive } = props;

  const filteredData =
    searchFilter && data
      ? data.filter(plan => plan.Code.indexOf(searchFilter) !== -1)
      : data || [];

  useEffect(() => {
    loadArchive();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} style={{ marginBottom: 15 }}>
        <SearchInput onSearch={setSearch} searchString={searchFilter} />
      </GridItem>
      <LoadingComponent loading={loading} />
      {filteredData.map(plan => (
        <GridItem xs={12} sm={6} md={4} lg={3} key={plan.Code}>
          <PricingPlanCard plan={plan} notActive={true} />
        </GridItem>
      ))}
    </GridContainer>
  );
};

const mapDispatchToProps = {
  loadArchive
};

const mapStateToProps = state => ({
  ...state.removedPlans
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plans);
