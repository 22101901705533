/* eslint react-hooks/exhaustive-deps: "off" */
import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SwitchBlock from "components/SwitchBlock/SwitchBlock";
import SearchInput from "components/SearchInput/SearchInput";
import PlansTable from "./PlansTable";
import PlansCards from "./PlansCards";

import { loadPlans } from "actions";

const Plans = props => {
  const [selected, setSelected] = React.useState("cards");
  const [searchFilter, setSearch] = useState("");

  const filteredData =
    selected === "cards" && searchFilter && props.data
      ? props.data.filter(plan => plan.Code.indexOf(searchFilter) !== -1)
      : props.data || [];

  useEffect(() => {
    props.loadPlans();
  }, []);

  return selected === "cards" ? (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <SearchInput onSearch={setSearch} searchString={searchFilter} />
          <SwitchBlock selected={selected} setSelected={setSelected} />
        </div>
      </GridItem>
      <PlansCards data={filteredData} loading={props.loading} />
    </GridContainer>
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <SwitchBlock selected={selected} setSelected={setSelected} />
        </div>
      </GridItem>
      <PlansTable data={props.data || []} loading={props.loading} />
    </GridContainer>
  );
};

const mapDispatchToProps = {
  loadPlans
};

const mapStateToProps = state => ({
  ...state.plans
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plans);
