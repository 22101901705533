import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";
import Basic from "./BasicIcon";
import Standard from "./StandardIcon";
import Pro from "./ProIcon";
import Overwritable from "@material-ui/icons/ThreeSixtyOutlined";
import Danger from "components/Typography/Danger.js";
import styles from "./styles";

const useStyles = makeStyles(styles);

const getType = Type => {
  switch (Type) {
    case "Standard":
      return { Icon: Standard, color: "success" };
    case "Pro":
      return { Icon: Pro, color: "warning" };
    case "Basic":
    default:
      return { Icon: Basic, color: "rose" };
  }
};

const PricingPlanCard = ({ plan, loading, actions, notActive, completed }) => {
  const {
    Code,
    BillingPeriod,
    GBytesToUpload,
    GBytesToDownload,
    Price,
    Overwrite,
    Type
  } = plan || {};

  const classes = useStyles();
  let { color, Icon } = getType(Type);
  color = !notActive && !completed ? color : "info";

  return (
    <Card>
      <LoadingComponent loading={loading} />
      <CardHeader color={color} stats icon>
        <CardIcon color={color}>
          <Icon />
        </CardIcon>
        <p className={classes.cardCategory}>{Type}</p>
        <h3 className={classes.cardTitle}>{Code}</h3>
        {completed ? (
          <p className={classes.cardClarify}>
            <Danger>Completed</Danger>
          </p>
        ) : null}
      </CardHeader>
      <CardBody profile>
        <div className={classes.cardRow}>
          <h6 className={classes.cardCategory}>Available Space</h6>
          {Overwrite ? (
            <p className={classes.stats} style={{ paddingTop: 10, margin: 0 }}>
              <Overwritable /> Overwritable
            </p>
          ) : null}
        </div>
        <div className={classes.cardRow}>
          <p className={classes.description}>To Download</p>
          <h3 className={classes.cardTitle}>
            {GBytesToDownload} <small>GB</small>
          </h3>
        </div>
        <div className={classes.cardRow}>
          <p className={classes.description}>To Upload </p>
          <h3 className={classes.cardTitle}>
            {GBytesToUpload} <small>GB</small>
          </h3>
        </div>
        <div className={classes.cardRow}>
          <p className={classes.description}>Billing Period</p>
          <h3 className={classes.cardTitle}>
            {BillingPeriod} <small>months</small>
          </h3>
        </div>
        <CardFooter product>
          <div className={classes.price}>
            <h4>${Price}/month</h4>
          </div>
          <div>{actions ? actions({ plan }) : null}</div>
        </CardFooter>
      </CardBody>
    </Card>
  );
};

export default PricingPlanCard;
