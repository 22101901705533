import { put, takeLatest, call } from "redux-saga/effects";
import * as api from "api";

function* getLogs() {
  try {
    yield put({ type: "START_LOADING_DATA", payload: "logs" });
    const response = yield call(api.fetchLogs);
    const data = (response.data && response.data.logs) || [];
    yield put({
      type: "ITEMS_RECEIVED",
      payload: { field: "logs", data, loading: false }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "plans", error: error.message }
    });
  }
}

export default function* logsSaga() {
  yield takeLatest("LOAD_LOGS", getLogs);
}
