/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from "react";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// react component for creating dynamic tables
import ReactTable from "react-table";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";

// @material-ui/icons
import LogsIcon from "@material-ui/icons/StorageOutlined";

import { loadLogs } from "actions";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const useStyles = makeStyles(styles);

const ActionsLogs = props => {
  const { data, loading, loadLogs } = props;
  useEffect(() => {
    loadLogs();
  }, []);

  const classes = useStyles();
  const items = (data || []).map(item => ({
    ...item,
    Modifications: item.Modifications.length ? (
      <div>
        {item.Modifications.map(event => (
          <p key={event}>{event}</p>
        ))}
      </div>
    ) : (
      item.Modifications[0]
    )
  }));
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <LogsIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Pricing Plans</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={items}
              columns={[
                {
                  Header: "User",
                  accessor: "UserName"
                },
                {
                  Header: "Modified By",
                  accessor: "ModifiedBy"
                },
                {
                  Header: "Modification Date",
                  accessor: "ModificationDate",
                  Cell: row => <span>{new Date(+row.value * 1000).toLocaleString()}</span>
                },
                {
                  Header: "Modifications",
                  accessor: "Modifications",
                  sortable: false,
                  filterable: false,
                  minWidth: 300
                }
              ]}
              filterable
              defaultPageSize={20}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
              loading={loading}
              LoadingComponent={LoadingComponent}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapDispatchToProps = {
  loadLogs
};

const mapStateToProps = state => ({
  ...state.logs
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionsLogs);
