import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Danger from "components/Typography/Danger.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import Warning from "@material-ui/icons/Warning";
import Timeline from "@material-ui/icons/Timeline";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";
import styles from "./styles";

const useStyles = makeStyles(styles);

const getRelativeValue = (used, total) => `${+used} /${+total} `;

const percents = (used, total) => (total - used) / total;

const getType = (toUpload, uploaded, toDownload, downloaded) => {
  const diff = Math.max(
    percents(toUpload, uploaded),
    percents(toDownload, downloaded)
  );

  return diff < 0.5 ? (diff < 0.1 ? "danger" : "warning") : "success";
};

const CustomCard = ({ user, loading, actions }) => {
  const {
    UserName,
    Folder,
    BytesToUpload = 0,
    BytesUploaded = 0,
    BytesDownloaded = 0,
    BytesToDownload = 0
  } = user || {};
  const classes = useStyles();
  const color = getType(
    BytesToUpload,
    BytesUploaded,
    BytesDownloaded,
    BytesToDownload
  );

  return (
    <Card>
      <LoadingComponent loading={loading} />
      <CardHeader color={color} stats icon>
        <CardIcon color={color}>
          <Timeline />
        </CardIcon>
        <p className={classes.cardCategory}>User</p>
        <h3 className={classes.cardTitle}>{UserName}</h3>
        <p className={classes.cardClarify}>{Folder}</p>
      </CardHeader>
      <CardBody profile>
        <h6 className={classes.cardCategory}>Used Space</h6>
        <div className={classes.cardRow}>
          <p className={classes.description}>Download</p>
          <h3 className={classes.cardTitle}>
            {getRelativeValue(BytesDownloaded, BytesToDownload)}
            <small>GB</small>
          </h3>
        </div>
        <div className={classes.cardRow}>
          <p className={classes.description}>Upload </p>
          <h3 className={classes.cardTitle}>
            {getRelativeValue(BytesUploaded, BytesToUpload)} <small>GB</small>
          </h3>
        </div>
        <CardFooter stats>
          <div
            className={classes.stats}
            style={color !== "danger" ? { visibility: "hidden" } : {}}
          >
            <Danger>
              <Warning />
            </Danger>
            <p className={classes.warningMsg}>Need to get more space</p>
          </div>
          {actions ? actions({ user }) : null}
        </CardFooter>
      </CardBody>
    </Card>
  );
};

export default CustomCard;
