import Amplify, { Auth } from "aws-amplify";

const REACT_APP_USER_POOL_CLIENT_ID = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const REACT_APP_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const REACT_APP_USER_POOL_REGION = process.env.REACT_APP_USER_POOL_REGION;

export async function getCurrentUser() {
  try {
    const cognitoUser = await getCognitoUser();
    const userSession = cognitoUser.getSignInUserSession();
    const user = {
      token: userSession.idToken.jwtToken,
      firstName: cognitoUser.attributes.given_name,
      lastName: cognitoUser.attributes.family_name,
      username: cognitoUser.username,
      email: cognitoUser.attributes.email,
      picture: cognitoUser.attributes.picture,
      session: cognitoUser.signInUserSession,
      sub: cognitoUser.attributes.sub
    };
    return user;
  } catch (error) {
    return null;
  }
}

export const logOut = () => Auth.signOut();
export const getCognitoUser = () => Auth.currentAuthenticatedUser();

Amplify.configure({
  Auth: {
    region: REACT_APP_USER_POOL_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_CLIENT_ID,
    authenticationFlowType: "USER_SRP_AUTH"
  }
});
