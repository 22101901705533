import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import CoreLayout from "layouts/CoreSmp";
import {
  ConfirmSignIn,
  ForgotPassword,
  SignIn,
  VerifyContact,
  RequireNewPassword,
  Loading,
  withAuthenticator
} from "aws-amplify-react";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const hist = createBrowserHistory();
const App = props => (
  <Router history={hist}>
    <Switch>
      <Route path="/main" component={CoreLayout} />
      <Redirect from="/" to="/main/users" />
    </Switch>
  </Router>
);

export default withAuthenticator(App, false, [
  <SignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <RequireNewPassword />,
  <ForgotPassword />,
  <Loading />
]);
