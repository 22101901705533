import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PlansIcon from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";

import PlansActions from "./PlansActions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const Overwritable = ({ value }) => (value ? <Check color="primary" /> : null);

const useStyles = makeStyles(styles);

const PlansTable = ({ data, loading }) => {
  const items = data.map(item => ({
    ...item,
    Overwrite: (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Overwritable value={item.Overwrite} />
      </div>
    ),
    actions: <PlansActions plan={item} />
  }));
  const classes = useStyles();

  return (
    <GridItem xs={12}>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <PlansIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Pricing Plans</h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={items}
            columns={[
              {
                Header: "Pricing Plan Code",
                accessor: "Code"
              },
              {
                Header: "Billing Period",
                accessor: "BillingPeriod"
              },
              {
                Header: "Gbs To Upload",
                accessor: "GBytesToUpload"
              },
              {
                Header: "Gbs To Download",
                accessor: "GBytesToDownload"
              },
              {
                Header: "Price",
                accessor: "Price"
              },
              {
                Header: "Type",
                accessor: "Type"
              },
              {
                Header: "Overwritable",
                accessor: "Overwrite",
                sortable: false,
                filterable: false,
                width: 150
              },
              {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
              }
            ]}
            filterable
            defaultPageSize={10}
            showPaginationTop
            showPaginationBottom={false}
            className="-striped -highlight"
            loading={loading}
            LoadingComponent={LoadingComponent}
          />
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default PlansTable;
