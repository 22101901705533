/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PermIdentity from "@material-ui/icons/PermIdentity";
import * as yup from "yup";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomCard from "components/UserCard/UserCard.js";
import UserForm from "components/UserForm/UserForm.js";
import PricingPlanCard from "components/PricingPlanCard/PricingPlanCard";
import LoadingComponent from "components/LoadingIndicator/LoadingIndicator";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";

import {
  getUser,
  updateUser,
  resetPassword,
  loadPlans,
  updateUserPlan,
  removeUserPlan
} from "actions";
const useStyles = makeStyles(styles);

const EditUser = props => {
  const classes = useStyles();
  const userName = new URLSearchParams(window.location.search).get("user");
  const { getUser, loadPlans, plans, data } = props;
  const user = data
    ? {
        ...data,
        PricingPlanId: data.PricingPlan ? data.PricingPlan.PlanId : ""
      }
    : null;

  const plansList = (plans && plans.data) || [];
  useEffect(() => {
    userName && getUser(userName);
  }, [userName, getUser]);

  useEffect(() => {
    !(plans && plans.data) && loadPlans();
  }, []);

  const editProps = {
    buttonText: "Update User",
    fields: ["BytesToUpload", "BytesToDownload"],
    validationSchema: yup.object().shape({
      BytesToDownload: yup
        .number()
        .required()
        .label("Bytes To Download"),
      BytesToUpload: yup
        .number()
        .required()
        .label("Bytes To Upload")
    })
  };

  const resetProps = {
    buttonText: "Reset User Password",
    fields: ["Password"],
    validationSchema: yup.object().shape({
      Password: yup
        .string()
        .required()
        .label("Password")
    })
  };

  const dict =
    user && user.PricingPlanId
      ? plansList.find(plan => plan.PlanId === user.PricingPlanId)
        ? plansList
        : [
            {
              ...user.PricingPlan,
              deleted: true
            }
          ].concat(plansList)
      : plansList;

  const updatePlanProps = {
    buttonText: "Update Pricing Plan",
    dictionaries: {
      PricingPlan: dict
    },
    fields: ["PricingPlan"]
  };

  const userPlan = ({ UserName, PricingPlanId }) => ({
    UserName,
    PricingPlan: plans.data.find(plan => plan.PlanId === PricingPlanId)
  });

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Edit User</h4>
            </CardHeader>
            <CardBody>
              <LoadingComponent loading={props.loading} />
              <h4 className={classes.cardIconTitle}>Update User Amounts</h4>
              <UserForm
                {...editProps}
                initialValues={user}
                onSubmit={props.updateUser}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 className={classes.cardIconTitle}>Set Pricing Plan</h4>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => props.removeUserPlan(user.UserName)}
                  color="danger"
                  className="remove"
                >
                  <Close />
                </Button>
              </div>
              <UserForm
                {...updatePlanProps}
                initialValues={
                  user && {
                    ...user,
                    PricingPlanId: user.PricingPlan
                      ? user.PricingPlan.PlanId
                      : ""
                  }
                }
                onSubmit={value => props.updateUserPlan(userPlan(value))}
              />
              <h4 className={classes.cardIconTitle}>Reset User Password</h4>
              <UserForm
                {...resetProps}
                initialValues={user}
                onSubmit={props.resetPassword}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomCard user={user} loading={props.loading} />
          {user && user.PricingPlan ? (
            <PricingPlanCard
              plan={user.PricingPlan}
              loading={props.loading}
              completed={user.ToBeApplied && !+user.ToBeApplied}
            />
          ) : null}
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapDispatchToProps = {
  getUser,
  updateUser,
  resetPassword,
  loadPlans,
  updateUserPlan,
  removeUserPlan
};

const mapStateToProps = state => ({
  ...state.user,
  plans: state.plans
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
