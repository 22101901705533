import { put, takeLatest, takeEvery, call } from "redux-saga/effects";
import * as api from "api";
import { getPlan } from "actions";

function* getPlans() {
  try {
    yield put({ type: "START_LOADING_DATA", payload: "plans" });
    const response = yield call(api.fetchPlans);
    const data = (response.data && response.data.plans) || [];
    yield put({
      type: "ITEMS_RECEIVED",
      payload: { field: "plans", data, loading: false }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "plans", error: error.message }
    });
  }
}

function* getArchive() {
  try {
    yield put({ type: "START_LOADING_DATA", payload: "removedPlans" });
    const response = yield call(api.fetchDeletedPlans);
    const data = (response.data && response.data.plans) || [];
    yield put({
      type: "ITEMS_RECEIVED",
      payload: { field: "removedPlans", data, loading: false }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "removedPlans", error: error.message }
    });
  }
}

function* addPlan(action) {
  try {
    yield put({ type: "START_LOADING_DATA", payload: "newplan" });
    yield call(api.createPlan, action.payload.plan);
    yield put({
      type: "ITEM_CREATED",
      payload: { field: "newplan", data: action.payload.plan }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "newplan", error: error.message }
    });
  }
}

function* deletePlan(action) {
  try {
    yield call(api.deactivatePlan, action.payload.plan);
    yield call(getPlans);
  } catch (error) {
    yield put({
      type: "SET_ACTION_ERROR",
      payload: { error: error.message }
    });
  }
}

function* getPlanById(action) {
  try {
    yield put({ type: "START_LOADING_DATA", payload: "plan" });
    const { data } = yield call(api.getPlan, action.payload.plan);
    yield put({
      type: "ITEM_RECEIVED",
      payload: { field: "plan", data: data.plan }
    });
  } catch (error) {
    yield put({
      type: "SET_DATA_ERROR",
      payload: { field: "plan", error: error.message }
    });
  }
}

function* updatePlan(action) {
  try {
    yield call(api.updatePlan, action.payload.plan);
    yield put(getPlan(action.payload.plan.PlanId));
  } catch (error) {
    yield put({
      type: "SET_ACTION_ERROR",
      payload: { error: error.message }
    });
  }
}

export default function* plansSaga() {
  yield takeLatest("LOAD_PLANS", getPlans);
  yield takeLatest("LOAD_ARCHIVE", getArchive);
  yield takeEvery("DELETE_PLAN", deletePlan);
  yield takeEvery("GET_PLAN", getPlanById);
  yield takeEvery("ADD_PLAN", addPlan);
  yield takeEvery("UPDATE_PLAN", updatePlan);
}
